import { isSameDay, isSameMonth } from 'date-fns'
import { format, toZonedTime } from 'date-fns-tz'

export const formatDateRange = ({
  startDate,
  endDate,
  timezone,
  timezoneAbbreviation,
  options = {
    hideYear: false,
    hideTime: false,
  },
}: {
  startDate: string | number
  endDate: string | number
  timezone: string
  timezoneAbbreviation?: string
  options?: {
    hideYear?: boolean
    hideTime?: boolean
  }
}) => {
  const start = toZonedTime(startDate, timezone)
  const end = toZonedTime(endDate, timezone)
  let formattedDate

  const formatDate = (date: Date, dateFormat: string) =>
    format(date, dateFormat, {
      timeZone: timezone,
    })

  if (isSameDay(start, end)) {
    // 15 November 2021
    formattedDate = formatDate(
      start,
      `d LLLL${options.hideYear ? '' : ' yyyy'}`
    )
  } else if (isSameMonth(start, end)) {
    // 15 - 17 November 2021
    formattedDate =
      formatDate(start, 'd – ') +
      formatDate(end, `d LLLL${options.hideYear ? '' : ' yyyy'}`)
  } else {
    // 29 November – 3 December 2021
    formattedDate =
      formatDate(start, 'd LLLL – ') +
      formatDate(end, `d LLLL${options.hideYear ? '' : ' yyyy'}`)
  }

  return `${formattedDate}${
    options.hideTime
      ? ''
      : `${format(
          start,
          `, HH:mm ${
            timezoneAbbreviation ? `'${timezoneAbbreviation}'` : 'zzz'
          }`,
          {
            timeZone: timezone,
          }
        )}`
  }`
}
