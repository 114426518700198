import { ReactNode, useId } from 'react'

import { CheckCircleIcon, CloseIcon } from '@nx/fire/assets'

import {
  IconPosition,
  StyledCancelCircle,
  StyledCheckCircle,
  StyledCheckbox,
  StyledCheckboxPillContainer,
  StyledLabel,
} from './fire-checkbox-pill.styles'

export interface FireCheckboxPillCommonProps {
  checked?: boolean
  disabled?: boolean
  id?: string
  onChange?: () => void
  iconPosition?: IconPosition
}

interface CheckboxWithLabelProps {
  children?: never
  label: ReactNode
}

interface CheckboxWithChildrenProps {
  children: string
  label?: never
}

export type FireCheckboxPillProps = FireCheckboxPillCommonProps &
  (CheckboxWithLabelProps | CheckboxWithChildrenProps)

export function FireCheckboxPill({
  checked = false,
  label,
  children,
  id,
  disabled = false,
  onChange,
  iconPosition = 'END',
  ...rest
}: FireCheckboxPillProps) {
  const codeId = useId()
  const checkBoxId = `${id || codeId}-checkbox`

  const handleChange = () => {
    if (!disabled) {
      onChange?.()
    }
  }

  return (
    <StyledCheckboxPillContainer>
      <StyledCheckbox
        type="checkbox"
        id={checkBoxId}
        checked={checked}
        disabled={disabled}
        onChange={handleChange}
        $iconPosition={iconPosition}
      />
      <StyledLabel
        htmlFor={checkBoxId}
        $disabled={disabled}
        $iconPosition={iconPosition}
        {...rest}
      >
        {iconPosition === 'START' && checked && (
          <StyledCheckCircle $disabled={disabled} $iconPosition={iconPosition}>
            <CheckCircleIcon width="20" height="20" />
          </StyledCheckCircle>
        )}
        {label || children}
        {iconPosition === 'END' && checked && (
          <StyledCancelCircle $disabled={disabled} $iconPosition={iconPosition}>
            <CloseIcon width="7" height="7" />
          </StyledCancelCircle>
        )}
      </StyledLabel>
    </StyledCheckboxPillContainer>
  )
}

export default FireCheckboxPill
