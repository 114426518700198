import styled, { css } from 'styled-components'

export type IconPosition = 'START' | 'END'

export const StyledCheckboxPillContainer = styled.div`
  position: relative;
  width: fit-content;
`

const StyledCommonIconWrapper = styled.div<StyledLabelProps>`
  position: absolute;
  top: 50%;
  transform: translateY(-50%) scale(0);
  transform-origin: center;
  display: grid;
  place-items: center;
  animation: icon-appear 0.25s forwards;
  animation-delay: 0.15s;
  opacity: 0;

  @keyframes icon-appear {
    100% {
      opacity: 1;
      transform: translateY(-50%) scale(1);
    }
  }
`

export const StyledCheckCircle = styled(StyledCommonIconWrapper)`
  left: 9px;
  background: ${({ theme, $disabled }) =>
    $disabled ? theme.colours.grey['45'] : theme.colours.primary.white};
  width: 20px;
  height: 20px;
  color: ${({ theme }) => theme.colours.primary.primary};
`

export const StyledCancelCircle = styled(StyledCommonIconWrapper)`
  right: 9px;
  background: ${({ theme, $disabled }) =>
    $disabled ? theme.colours.grey['45'] : theme.colours.primary.primary};
  width: 13px;
  height: 13px;
  border-radius: 50%;
  color: ${({ theme }) => theme.colours.primary.white};
`

interface StyledLabelProps {
  $iconPosition: IconPosition
  $disabled?: boolean
}

export const StyledLabel = styled.label<StyledLabelProps>`
  background: ${({ theme }) => theme.colours.primary.white};
  display: grid;
  place-items: center;
  border: 1px solid
    ${({ theme, $disabled }) =>
      $disabled ? theme.colours.grey['30'] : theme.colours.grey['45']};
  border-radius: 500px;
  height: 32px;
  cursor: pointer;
  padding: 0 12px;
  transition: ${({ $iconPosition }) =>
    $iconPosition === 'END' ? 'padding-right 0.25s' : 'padding-left 0.25s'};
  white-space: nowrap;
  user-select: none;
  z-index: -1;
  ${({ theme, $disabled }) =>
    $disabled &&
    css`
      color: ${theme.colours.grey['60']};
    `}

  &:hover {
    border-color: ${({ theme, $disabled }) =>
      !$disabled && theme.colours.primary.primary};
  }
`

export const StyledCheckbox = styled.input<{ $iconPosition: IconPosition }>`
  all: unset;
  display: block;

  &:focus ~ ${StyledLabel} {
    border-color: ${({ theme, disabled }) =>
      disabled ? theme.colours.grey['30'] : theme.colours.primary.primary};
  }

  &:focus:not(:focus-visible, :checked, :hover) ~ ${StyledLabel} {
    border-color: ${({ theme, disabled }) =>
      disabled ? theme.colours.grey['30'] : theme.colours.grey['45']};
  }

  &:focus-visible ~ ${StyledLabel} {
    border-color: ${({ theme, disabled }) =>
      disabled ? theme.colours.grey['30'] : theme.colours.primary.primary};
  }

  &:checked ~ ${StyledLabel} {
    position: relative;
    z-index: 1;
    border-color: ${({ theme, disabled }) =>
      !disabled && theme.colours.primary.primary};

    ${({ $iconPosition }) => css`
      ${$iconPosition === 'END' ? 'padding-right: 30px' : 'padding-left: 35px'}
    `};
  }
`
